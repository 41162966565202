import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const EventCreationIcon: FC<SVGIconProps> = ({ className }) => (
  <svg
    className={classNames("fill-current", className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M6 2h2v2h8V2h2v2h3v16a2 2 0 01-2 2H5a2 2 0 01-2-2V4h3V2zM5 6h14v14H5V6zm6.5 12a.5.5 0 01-.5-.5V14H7.5a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5H11V8.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5V12h3.5a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H13v3.5a.5.5 0 01-.5.5h-1z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default EventCreationIcon;
