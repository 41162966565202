import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  BillingRateType,
  SessionBillingApi,
  SessionBillingCountingRule,
  SessionBillingCycle,
} from "@practice/sdk";
import { toNumber } from "lodash";

import { useAuth } from "@contexts/auth";
import useLogger from "@hooks/use-logger";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";
import { SVGIconProps } from "@lib/shared-types";
import { catchErrorSDK } from "@lib/utils/catch-error-client";

import SmallBanner from "@components/Banner/SmallBanner";
import { Button } from "@components/Button";
import { ErrorMessage } from "@components/ErrorMessage";
import ClientIcon from "@components/Icons/ClientIcon";
import ClockIcon from "@components/Icons/ClockIcon";
import FlagIcon from "@components/Icons/Flag3Icon";
import InfoIcon from "@components/Icons/InfoIcon";
import LinkedIcon from "@components/Icons/LinkedIcon";
import MergeIcon from "@components/Icons/MergeIcon";
import StoreIcon from "@components/Icons/StoreIcon";
import Modal, { ModalProps } from "@components/Modals/Modal";

import BillingPreferencesHeaderModal from "./BilingPreferencesHeaderModal";
import CountingRuleStepField from "./CountingRuleStepField";
import FinalStep from "./FinalStep";
import HourlyRatesStepFields from "./HourlyRatesStepFields";
import SetupStepFields from "./SetupStepFields";
import {
  FormDataType,
  getCycle,
  getFirstPayPeriodDate,
  getValidRateTypes,
} from "./utils";

interface BillingPreferencesModalProps extends ModalProps {
  onClickClose: () => void;
}

type StepsDataItem = {
  title: string;
  description: string;
  buttonLabel: string;
  info: {
    Icon: FC<SVGIconProps>;
    text: string;
  }[];
};

const stepsData: Record<number, StepsDataItem> = {
  1: {
    title: "Set up accounts payable",
    description:
      "Customize how you manage & track your teams pay period, Practice will do the heavy lifting for you.",
    buttonLabel: "Continue with preferences",
    info: [
      {
        Icon: InfoIcon,
        text: "Once set, your preferences will be locked. We’ll help you change your settings if needed.",
      },
    ],
  },
  2: {
    title: "Define payable sessions",
    description:
      "Practice will count sessions towards a pay period depending on the outcome marked on each appointment.",
    buttonLabel: "Continue with rules",
    info: [
      {
        Icon: FlagIcon,
        text: "When an appointment’s outcome is changed, we’ll account it for billing",
      },
    ],
  },
  3: {
    title: "Customize default rates",
    description:
      "Practice allows you to customize how you pay your team for different services. Define your rates and use them with different packages.",
    buttonLabel: "Finalize setup",
    info: [
      {
        Icon: ClockIcon,
        text: "Rates are automatically calculated each pay period",
      },
      {
        Icon: StoreIcon,
        text: "Calculation is dependent on session duration.",
      },
      {
        Icon: MergeIcon,
        text: "Overwrite default rates for specific members at anytime",
      },
    ],
  },
  4: {
    title: "Let's get rolling",
    description:
      "If you need to make changes down the road, our team is here to help!",
    buttonLabel: "Got it, looks good",
    info: [
      {
        Icon: FlagIcon,
        text: "Tell your team about marking session as “Complete” to account for billing",
      },
      {
        Icon: LinkedIcon,
        text: "Link rates to your package where you’d like to track billing",
      },
      {
        Icon: ClientIcon,
        text: "Third point, something something “it’s easy to set things up”",
      },
    ],
  },
};

const BillingPreferencesModal: FC<BillingPreferencesModalProps> = ({
  onClickClose,
  ...props
}) => {
  const totalSteps = 4;
  const [step, setStep] = useState(1);
  const isLastStep = step === totalSteps;
  const data = stepsData[step];
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { oid, organization, account } = useAuth();
  const { logger } = useLogger();
  const snackbar = useSnackbar();
  const sessionBillingApi = useSDKApi(SessionBillingApi);
  const generateRequestId = useRequestIdGenerator("AccountsPayableSetup");
  const formMethods = useForm<FormDataType>({
    defaultValues: {
      currency: organization?.lastUsedCurrency ?? "USD",
      payPeriod: SessionBillingCycle.Monthly,
      payPeriodStart: "monday",
      firstPayPeriod: "next",
      countingRule: SessionBillingCountingRule.CompleteOnly,
      acceptedOutcomes: [],
      rateTypes: [{ title: "default rate" }],
      timeZone: account?.timeZone ?? undefined,
    },
  });
  const { handleSubmit, getValues } = formMethods;

  const onSubmit = async (data: FormDataType) => {
    if (!oid) return;

    setIsLoading(true);

    try {
      const { rateTypes } = data;
      const validRateTypes = getValidRateTypes(rateTypes);
      const targetDate = getFirstPayPeriodDate(data);
      const billingRates = validRateTypes.map((rateType) => ({
        title: rateType.title,
        value: toNumber(rateType.value),
        type: BillingRateType.Session,
      }));
      const cycle = getCycle(data);
      const result = await sessionBillingApi.setupSessionBilling({
        organizationId: oid,
        setupSessionBillingRequest: {
          currency: data.currency,
          cycle,
          startDate: targetDate,
          countingRule: data.countingRule,
          acceptedOutcomes: data.acceptedOutcomes,
          timeZone: data.timeZone,
          billingRates,
          endDay: data.firstPayPeriodEnd
            ? toNumber(data.firstPayPeriodEnd)
            : undefined,
        },
        xRequestId: generateRequestId(),
      });

      if (result.success) {
        snackbar.showMessage("Billing preferences saved successfully");
        onClickClose();
      }
    } catch (error: unknown) {
      logger.error("BillingPreferencesModal", error);
      const errorMessage = await catchErrorSDK(error, "Something went wrong");
      snackbar.showWarning(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const validateRateTypes = () => {
    const { rateTypes } = getValues();
    const validRateTypes = getValidRateTypes(rateTypes);
    return validRateTypes.some(
      (rateType) => !rateType?.title || !rateType?.value
    );
  };

  const clearError = () => {
    if (error) {
      setError(null);
    }
  };

  const handleClickNext = () => {
    clearError();
    if (step === totalSteps) {
      return;
    }

    setStep((prevStep) => {
      if (prevStep === 2) {
        const { acceptedOutcomes } = getValues();
        if (!acceptedOutcomes.length) {
          setError("Please select at least one outcome");
          return prevStep;
        }
      } else if (prevStep === 3) {
        const hasIncompleteItems = validateRateTypes();
        if (hasIncompleteItems) {
          setError("Please fill the rate type fields correclty");
          return prevStep;
        }
      }
      return prevStep + 1;
    });
  };

  const handleClickBack = () => {
    clearError();
    if (step === 1) {
      onClickClose();
      return;
    }
    setStep((prevStep) => prevStep - 1);
  };

  const renderFormByStep = () => {
    const methodsByStep: Record<number, FC<{}>> = {
      1: SetupStepFields,
      2: CountingRuleStepField,
      3: HourlyRatesStepFields,
      4: FinalStep,
    };
    const StepComponent = methodsByStep[step];
    return <StepComponent />;
  };

  return (
    <Modal size="bigger" className="p-8" disableClose {...props}>
      <form className="flex flex-col sm:flex-row space-y-8 sm:space-x-8 sm:space-y-0">
        <div className="flex-1 min-w-0">
          <BillingPreferencesHeaderModal
            step={step}
            onClickBack={handleClickBack}
            isLastStep={isLastStep}
            title={data.title}
            description={data.description}
          />
          {!!error && <ErrorMessage className="mb-4">{error}</ErrorMessage>}
          <div className="min-h-[273px]">
            <FormProvider {...formMethods}>{renderFormByStep()}</FormProvider>
          </div>
          <Button
            className="w-full mt-8"
            onClick={!isLastStep ? handleClickNext : handleSubmit(onSubmit)}
            variant={isLastStep ? "primary" : "lightPrimary"}
            isLoading={isLoading}
          >
            {data.buttonLabel}
          </Button>
        </div>
        {/**
         * @TODO: enable it back replacing the next `div` for the one below
         * once the ilustrations are ready: WEB-8755
         * <div className="hidden flex flex-col flex-1 bg-grey-950 rounded-2xl">
         */}
        <div className="hidden">
          <div className="flex-1">Illustration on how it works</div>
          <SmallBanner
            items={data.info.map(({ Icon, ...item }) => {
              const FormattedIcon = () => (
                <Icon className="text-grey-500 w-6 flex-none" />
              );
              return { Icon: FormattedIcon, ...item };
            })}
          />
        </div>
      </form>
    </Modal>
  );
};

export default BillingPreferencesModal;
