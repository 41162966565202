import { useMemo } from "react";
import { BillingTransactionApi, InvoiceApi } from "@practice/sdk";
import axios from "axios";
import { FieldPath, UpdateData } from "firebase/firestore";
import useSWR from "swr";
import { useCollection, useDocument } from "swr-firebase";

import { useAuth } from "@contexts/auth";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { fixAppointment } from "@lib/utils/appointments";
import { catchErrorSDK } from "@lib/utils/catch-error-client";

import { useGetCalendarOwnerCallback } from "@components/availabilities/AvailabilityOwnerPreview";

type UseAppointmentType = (
  oid?: string,
  appointmentId?: string
) => {
  appointment?: AppointmentType;
  loading: boolean;
  isValidating: boolean;
  update: (data: UpdateData<AppointmentType>) => Promise<void> | null;
  cancelAppointment: (
    sendEmailNotifications?: boolean,
    origin?: "client" | "coach",
    updateOutcome?: boolean,
    outcomeId?: string,
    reason?: string
  ) => void;
  siblings?: AppointmentType[];
  loadingSiblings: boolean;
  samePackageAppointments?: AppointmentType[];
  loadingSamePackageAppointments: boolean;
};

const useAppointment: UseAppointmentType = (oid, appointmentId) => {
  const {
    data: appointment,
    loading: loadingAppointment,
    isValidating,
    update,
  } = useDocument<AppointmentType>(
    oid && appointmentId ? `users/${oid}/appointments/${appointmentId}` : null,
    {
      listen: true,
    }
  );

  const { data: siblings, loading: loadingSiblings } =
    useCollection<AppointmentType>(`users/${oid}/appointments`, {
      listen: true,
      where: [
        "gcal.event.recurringEventId" as unknown as FieldPath, // hack to support nested fields
        "==",
        appointment?.gcal?.event?.recurringEventId,
      ],
    });

  const {
    data: samePackageAppointments,
    loading: loadingSamePackageAppointments,
  } = useCollection<AppointmentType>(`users/${oid}/appointments`, {
    listen: true,
    where: ["packageInstanceId", "==", appointment?.packageInstanceId],
  });

  const cancelAppointment = (
    sendEmailNotifications?: boolean,
    origin?: "client" | "coach",
    updateOutcome?: boolean,
    outcomeId?: string,
    reason?: string
  ) => {
    axios.post(`/api/users/${oid}/appointments/cancel`, {
      appointmentId,
      ...(sendEmailNotifications && { sendEmailNotifications }),
      ...(origin && { origin }),
      ...(updateOutcome && { updateOutcome }),
      ...(outcomeId && { outcomeId }),
      ...(reason && { reason }),
    });
  };

  const getCalendarOwner = useGetCalendarOwnerCallback();

  const fixedAppointment = useMemo(() => {
    return appointment && appointment.exists && appointment.status !== "deleted"
      ? fixAppointment(appointment, oid, getCalendarOwner)
      : undefined;
  }, [getCalendarOwner, oid, appointment]);

  const fixedSiblings = useMemo(() => {
    return siblings?.map((appointment) => {
      return fixAppointment(appointment, oid, getCalendarOwner);
    });
  }, [getCalendarOwner, oid, siblings]);

  const fixedSamePackageAppointments = useMemo(() => {
    return samePackageAppointments?.map((appointment) => {
      return fixAppointment(appointment, oid, getCalendarOwner);
    });
  }, [getCalendarOwner, oid, samePackageAppointments]);

  return {
    appointment: fixedAppointment,
    loading: loadingAppointment,
    isValidating,
    update,
    cancelAppointment,
    siblings: fixedSiblings,
    loadingSiblings,
    samePackageAppointments: fixedSamePackageAppointments,
    loadingSamePackageAppointments,
  };
};

/**
 * Gets the reconciliation invoice related to the appointment
 * */
export const useReconciliationInvoiceAppointmentRelated = (
  appointmentId?: string
) => {
  const { oid } = useAuth();
  const invoiceApi = useSDKApi(InvoiceApi);
  const generateRequestId = useRequestIdGenerator(
    "use-reconciliation-invoice-appointment-related"
  );

  const swrData = useSWR(
    appointmentId
      ? `organizations/${oid}/appointments/${appointmentId}/get-invoice-related`
      : null,
    async () => {
      if (!oid || !appointmentId) return;
      try {
        return invoiceApi.getAppointmentInvoiceRelated({
          xRequestId: generateRequestId(),
          organizationId: oid,
          appointmentId,
        });
      } catch (error) {
        const errorMessage = await catchErrorSDK(
          error,
          "Something went wrong while fetching the invoice related to the appointment"
        );
        return {
          data: null,
          error: errorMessage,
        };
      }
    },
    {
      // we don't need to revalidate the invoice in this case
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onErrorRetry: (error) => {
        // never retry to fetch if invoice is not found
        if (error.status === 404) return;
      },
    }
  );

  return {
    data: swrData.data,
    isLoading: !swrData.data && !swrData.error,
    error: swrData.error,
  };
};

/**
 * Gets the reconciliation invoice related to the appointment
 * */
export const useAppointmentBillingTransaction = (appointmentId?: string) => {
  const { oid } = useAuth();
  const billingTransactionApi = useSDKApi(BillingTransactionApi);
  const generateRequestId = useRequestIdGenerator(
    "use-appointment-billing-transaction"
  );

  const swrData = useSWR(
    appointmentId
      ? `organizations/${oid}/appointments/${appointmentId}/get-billing-transaction`
      : null,
    async () => {
      if (!oid || !appointmentId) return;
      try {
        return billingTransactionApi.getAppointmentBillingTransaction({
          xRequestId: generateRequestId(),
          organizationId: oid,
          appointmentId,
        });
      } catch (error) {
        const errorMessage = await catchErrorSDK(
          error,
          "Something went wrong while fetching the billing transaction of an appointment"
        );
        return {
          data: null,
          error: errorMessage,
        };
      }
    },
    {
      onErrorRetry: (error) => {
        // never retry to fetch if invoice is not found
        if (error.status === 404) return;
      },
    }
  );

  return {
    data: swrData.data,
    isLoading: !swrData.data && !swrData.error,
    error: swrData.error,
  };
};

export default useAppointment;
