import { useEffect, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import SelectForm from "@components/Form/SelectForm";

import {
  getSecondPayPeriodEnd,
  getSecondPayPeriodStart,
  payPeriodCadenceOptions,
  payPeriodStartMonthDaysOptions,
} from "./utils";

export const CustomPayPeriod = () => {
  const { control, watch, setValue } = useFormContext();
  const firstPayPeriodStart = watch("firstPayPeriodStart");
  const firstPayPeriodEnd = watch("firstPayPeriodEnd");
  const cadence = watch("payPeriodCadence");

  useEffect(() => {
    const secondPayPeriodStart = getSecondPayPeriodStart(firstPayPeriodEnd);
    const secondPayPeriodEnd = getSecondPayPeriodEnd(firstPayPeriodStart);
    if (cadence === "monthly") {
      return setValue("firstPayPeriodEnd", secondPayPeriodEnd?.value);
    }
    setValue("secondPayPeriodStart", secondPayPeriodStart?.value);
    setValue("secondPayPeriodEnd", secondPayPeriodEnd?.value);
  }, [firstPayPeriodStart, firstPayPeriodEnd, setValue, cadence]);

  const renderEndDaySelector = useMemo(() => {
    const isDisabled = cadence === "monthly";
    const options = isDisabled
      ? payPeriodStartMonthDaysOptions.map((p) => ({
          ...p,
          label: p.label.replace("of month", "of next month"),
        }))
      : payPeriodStartMonthDaysOptions.filter((item) => {
          const value = parseInt(item.value);
          return value > parseInt(firstPayPeriodStart);
        });
    return (
      <SelectForm
        name="firstPayPeriodEnd"
        label="First pay period end"
        disabled={isDisabled}
        options={options}
        control={control}
      />
    );
  }, [cadence, firstPayPeriodStart, control]);

  const renderSecondPayPeriodInformation = useMemo(() => {
    if (cadence !== "twice-per-month") {
      return;
    }
    return (
      <>
        <SelectForm
          name="secondPayPeriodStart"
          label="Second pay period start"
          options={payPeriodStartMonthDaysOptions}
          disabled={true}
          control={control}
        />
        <SelectForm
          name="secondPayPeriodEnd"
          label="Second pay period end"
          options={payPeriodStartMonthDaysOptions}
          disabled={true}
          control={control}
        />
      </>
    );
  }, [cadence, control]);

  return (
    <>
      <div className="col-span-2">
        <SelectForm
          name="payPeriodCadence"
          label="Pay period cadence"
          options={payPeriodCadenceOptions}
          control={control}
        />
      </div>
      <SelectForm
        name="firstPayPeriodStart"
        label="First pay period start"
        options={payPeriodStartMonthDaysOptions}
        control={control}
      />
      {renderEndDaySelector}
      {renderSecondPayPeriodInformation}
    </>
  );
};
