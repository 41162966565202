import {
  BillingRate,
  BillingRateStatus,
  GetSessionBillingCycles200ResponseInner,
  SessionBillingCountingRule,
  SessionBillingCycle,
} from "@practice/sdk";
import { endOfMonth } from "date-fns";
import { compact, isEmpty, kebabCase, orderBy } from "lodash";
import moment from "moment";

export interface RateType {
  title: string;
  value: string;
  icon?: string;
}

export const payPeriodCadenceOptions = [
  { label: "Twice per month", value: SessionBillingCycle.TwicePerMonth },
  { label: "Once per month", value: SessionBillingCycle.Monthly },
];

export type CycleType = GetSessionBillingCycles200ResponseInner;

export type OptionItemType = {
  label: string;
  value: string;
  start?: Date;
  end?: Date;
};

export type ExtendedRateType = RateType & {
  id: string;
  status: BillingRateStatus;
  overwriting?: BillingRate["overwriting"];
};

export type FormDataType = {
  currency: string;
  payPeriod: SessionBillingCycle | "custom";
  payPeriodStart: string;
  firstPayPeriod: "next" | "last";
  countingRule: SessionBillingCountingRule;
  acceptedOutcomes: string[];
  rateTypes: RateType[];
  timeZone: string;
  firstPayPeriodStart?: string;
  firstPayPeriodEnd?: string;
  payPeriodCadence?: "monthly" | "twice-per-month";
};

export type BillingStatusType = "paid" | "unpaid";

export type BillingSummaryCycleType = {
  id: string;
  status: BillingStatusType;
  date: Date;
  title: string;
  total: number;
};

export const getValidRateTypes = (rateTypes: RateType[]) => compact(rateTypes);

export const payPeriodOptions = [
  { label: "Weekly", value: SessionBillingCycle.Weekly },
  { label: "Bi-weekly", value: SessionBillingCycle.BiWeekly },
  { label: "Monthly", value: SessionBillingCycle.Monthly },
  { label: "Custom", value: "custom" },
];

export const getPayPeriodLabel = (payPeriod: SessionBillingCycle) => {
  const label = payPeriodOptions.find((item) => item.value === payPeriod)
    ?.label;
  if (!label) {
    const customLabel = payPeriodCadenceOptions.find(
      (item) => item.value === payPeriod
    )?.label;
    return customLabel ?? "Custom";
  }
  return label;
};

export const weekDaysNumbers: Record<string, number> = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

export const payPeriodStartOptions = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];

export const payPeriodStartMonthDaysOptions = [
  { label: "1st of month", value: "1" },
  { label: "2nd of month", value: "2" },
  { label: "3rd of month", value: "3" },
  { label: "4th of month", value: "4" },
  { label: "5th of month", value: "5" },
  { label: "6th of month", value: "6" },
  { label: "7th of month", value: "7" },
  { label: "8th of month", value: "8" },
  { label: "9th of month", value: "9" },
  { label: "10th of month", value: "10" },
  { label: "11th of month", value: "11" },
  { label: "12th of month", value: "12" },
  { label: "13th of month", value: "13" },
  { label: "14th of month", value: "14" },
  { label: "15th of month", value: "15" },
  { label: "16th of month", value: "16" },
  { label: "17th of month", value: "17" },
  { label: "18th of month", value: "18" },
  { label: "19th of month", value: "19" },
  { label: "20th of month", value: "20" },
  { label: "21st of month", value: "21" },
  { label: "22nd of month", value: "22" },
  { label: "23rd of month", value: "23" },
  { label: "24th of month", value: "24" },
  { label: "25th of month", value: "25" },
  { label: "26th of month", value: "26" },
  { label: "27th of month", value: "27" },
  { label: "28th of month", value: "28" },
  { label: "29th of month", value: "29" },
  { label: "30th of month", value: "30" },
  { label: "31st of month", value: "31" },
  { label: "Last of the month", value: "last" },
];

export const getPayPeriodStartOptions = (payPeriod: string) => {
  if (payPeriod === "once-per-month" || payPeriod === "twice-per-month") {
    return payPeriodStartMonthDaysOptions;
  }
  return payPeriodStartOptions;
};

export const getFirstPayPeriodOptions = (periodStart: string) => {
  const selectedPeriodStartLabel =
    payPeriodStartOptions.find((item) => item.value === periodStart)?.label ??
    "Monday";
  return [
    { label: `Last ${selectedPeriodStartLabel}`, value: "last" },
    { label: `Next ${selectedPeriodStartLabel}`, value: "next" },
  ];
};

export function getSecondPayPeriodStart(firstPayPeriodEnd: string) {
  const firstPayPeriodEndIndex = payPeriodStartMonthDaysOptions.findIndex(
    (item) => item.value === firstPayPeriodEnd
  );
  const nextIndex = firstPayPeriodEndIndex + 1;
  return payPeriodStartMonthDaysOptions[nextIndex];
}

export function getFirstPayPeriodStart(startDay: string) {
  const firstPayPeriodIndex = payPeriodStartMonthDaysOptions.findIndex(
    (item) => item.value === startDay
  );
  return payPeriodStartMonthDaysOptions[firstPayPeriodIndex];
}

export function getSecondPayPeriodEnd(firstPayPeriodStart: string) {
  const firstPayPeriodEndIndex = payPeriodStartMonthDaysOptions.findIndex(
    (item) => item.value === firstPayPeriodStart
  );
  const beforeIndex = firstPayPeriodEndIndex - 1;
  if (beforeIndex < 0) {
    return {
      label: "Last day of the month",
      value: "last",
    };
  }
  return payPeriodStartMonthDaysOptions[beforeIndex];
}

export const formattedCountingRule: Record<SessionBillingCountingRule, string> =
  {
    [SessionBillingCountingRule.AllSessions]: "All sessions",
    [SessionBillingCountingRule.CompleteOnly]: "Complete sessions only",
  };

function buildDateFromMonthDay(monthDay: string) {
  if (monthDay === "last") {
    return endOfMonth(new Date());
  }
  const parsed = parseInt(monthDay);
  const now = new Date();
  if (now.getDate() <= parsed) {
    return new Date(now.getFullYear(), now.getMonth(), parsed);
  }
  return new Date(now.getFullYear(), now.getMonth() + 1, parsed);
}

/**
 * Gets the target date for the first pay period
 * */
export const getFirstPayPeriodDate = (data: FormDataType) => {
  if (data.firstPayPeriodStart) {
    return buildDateFromMonthDay(data.firstPayPeriodStart);
  }
  const today = moment();
  const todayWeekDay = today.isoWeekday();
  const weekDayNumber = weekDaysNumbers[data.payPeriodStart];
  const isFirstDateFuture = data.firstPayPeriod === "next";
  let targetDate = today;

  if (todayWeekDay <= weekDayNumber && isFirstDateFuture) {
    targetDate = moment().isoWeekday(weekDayNumber);
  } else {
    if (isFirstDateFuture) {
      targetDate = moment().add(1, "weeks").isoWeekday(weekDayNumber);
    } else {
      targetDate = moment().subtract(1, "weeks").isoWeekday(weekDayNumber);
    }
  }

  return targetDate.toDate();
};

export const getCycle = (data: FormDataType): SessionBillingCycle => {
  if (data.payPeriod === "custom") {
    if (data.payPeriodCadence === "twice-per-month") {
      return SessionBillingCycle.TwicePerMonth;
    }
    return SessionBillingCycle.Monthly;
  }
  return data.payPeriod;
};

/**
 * Creates the member form data from rate types when it has the
 * overwriting property
 * */
export const getMemberOverwritingRateTypes = (
  accountId: string,
  data: ExtendedRateType[]
) => {
  const formattedData = data.map((item) => ({
    ...item,
    value: item?.overwriting?.[accountId]?.value ?? item.value,
  }));

  return formattedData;
};

/**
 * Formats a number in a currency format
 * */
export const formatValueCurrency = (
  value: number,
  currency: string,
  minimumFractionDigits: number = 0
) =>
  value.toLocaleString("en-US", {
    style: "currency",
    currency: isEmpty(currency) ? "USD" : currency,
    minimumFractionDigits,
  });

/**
 * Format an array of cycles to be used in the select box
 * */
export const getCyclesAsSelectOptions = (
  cycles: CycleType[]
): OptionItemType[] =>
  orderBy(cycles, "start", "desc").map((cycle) => ({
    value: `cycle-${kebabCase(cycle.label)}`,
    label: cycle.label,
    start: cycle.start,
    end: cycle.end,
  }));
