import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ActivityAction,
  ActivityApi,
  ActorType,
  ExtendedAccount,
  ResourceType,
} from "@practice/sdk";
import {
  addOrganizationAccount,
  removeOrganizationAccount,
} from "api-services/definitions/accounts";
import { useApi } from "api-services/endpoints";
import { format } from "date-fns";
import { compact, orderBy } from "lodash";
import { useRouter } from "next/router";

import { useAuth } from "@contexts/auth";
import useAccessType from "@hooks/use-access-type";
import useHasFeature from "@hooks/use-has-feature";
import { useListConfiguration } from "@hooks/use-list-configuration";
import {
  useHasFullAccess,
  useOrganizationAccounts,
  useOrganizationIntegrations,
} from "@hooks/use-organization-accounts";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";
import useToggle from "@hooks/use-toggle";
import { FeatureNames } from "@lib/constants/featureNames";
import { displayNameFromContact } from "@lib/contacts";
import { AccountType } from "@lib/data/schemas/account";
import { getCoachBaseUrl } from "@lib/utils";
import catchErrorClient from "@lib/utils/catch-error-client";

import AppFrame from "@components/App/AppFrame";
import IntegrationInformation from "@components/AppointmentTypeForm/RoundRobinSettings/IntegrationInformation";
import { Button } from "@components/Button";
import ClientAvatar from "@components/Client/ClientAvatar";
import { PersonInformation } from "@components/Contact";
import { DropdownItem } from "@components/Dropdowns/SecondaryDropdown";
import IconAction from "@components/IconAction";
import BlockIcon from "@components/Icons/BlockIcon";
import CalendarIcon from "@components/Icons/CalendarIcon";
import CheckIcon from "@components/Icons/CheckIcon";
import ClientCheckIcon from "@components/Icons/ClientCheckIcon";
import EmailIcon from "@components/Icons/EmailIcon";
import InfoIcon from "@components/Icons/InfoIcon";
import PencilIcon from "@components/Icons/PencilIcon";
import PendingIcon from "@components/Icons/PendingIcon";
import PlusIcon from "@components/Icons/PlusIcon";
import ProBadgeIcon from "@components/Icons/ProBadgeIcon";
import RippleIcon from "@components/Icons/RippleIcon";
import TabIcon from "@components/Icons/TabIcon";
import TextboxIcon from "@components/Icons/TextboxIcon";
import ZoomIcon from "@components/Icons/ZoomIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import AccessPermission from "@components/MemberPermission/AccessPermission";
import MemberPermissionModal, {
  FormData,
} from "@components/MemberPermission/MemberPermissionModal";
import RemoveMemberModal from "@components/MemberPermission/RemoveMemberModal";
import SmallModal, { SmallModalProps } from "@components/Modals/SmallModal";
import SubscriptionPlanModal from "@components/Plans/SubscriptionPlanModal";
import { Redirect } from "@components/Redirect";
import {
  SelectableList,
  SelectableListContextProvider,
} from "@components/SelectableList";
import TeamsMarketingSection from "@components/Teams/TeamsMarketingSection";
import TeamsSidebar from "@components/Teams/TeamsSidebar";

interface DateFieldProps {
  ISODate: string | Date;
  text: string;
  textClass: string;
}

const DateField: FC<DateFieldProps> = ({ ISODate, text, textClass }) => {
  if (!ISODate) return <div />;

  return (
    <div className="flex items-center text-sm min-w-0">
      <span className={textClass}>{text}</span>
      <span className="mx-2 text-grey-500">•</span>
      <div className="text-grey-500 ">
        {format(new Date(ISODate), "ccc, MMM d, u")}
      </div>
    </div>
  );
};

const ActivityMessage: React.FC<{ account: ExtendedAccount }> = ({
  account,
}) => {
  if (!account.lastRefreshTime) {
    return (
      <DateField
        ISODate={account.createdAt}
        text="Invitation sent"
        textClass="text-grey-500"
      />
    );
  }

  return (
    <DateField
      ISODate={account.lastRefreshTime}
      text="Last active"
      textClass="text-action-500"
    />
  );
};

export const AccountIntegrations: React.FC<{
  account: ExtendedAccount;
}> = ({ account }) => {
  const { integrations } = useOrganizationIntegrations();
  return (
    <div className="flex items-center  space-x-2">
      <IntegrationInformation
        hasIntegration={integrations!.calendars.some(
          (int) => int.memberId === account.id
        )}
        Icon={CalendarIcon}
      />
      <IntegrationInformation
        hasIntegration={integrations!.emails.some(
          (int) => int.memberId === account.id
        )}
        Icon={EmailIcon}
      />
      <IntegrationInformation
        hasIntegration={integrations!.zoom.some(
          (int) => int.memberId === account.id
        )}
        Icon={ZoomIcon}
      />
    </div>
  );
};

const getColumns = (account: ExtendedAccount) => {
  return {
    second: <ActivityMessage account={account} />,
    third: <AccountIntegrations account={account} />,
    fourth: (
      <AccessPermission type={account?.accessType} isOwner={account.isOwner} />
    ),
  };
};

const sharedSmallModalProps: Partial<SmallModalProps> = {
  onActionText: "Ok, got it",
  iconColor: "green",
  icon: CheckIcon,
  showCancel: false,
  show: true,
};

const getDate = (account: ExtendedAccount): Date | string =>
  account.lastRefreshTime ?? account.createdAt;

const SORT_OPTIONS = [
  {
    icon: <RippleIcon />,
    text: "Activity",
    value: "activity",
    transform: (items: ExtendedAccount[]) => {
      return orderBy(items, [getDate], ["desc"]);
    },
  },
  {
    icon: <TextboxIcon />,
    text: "A-Z",
    value: "name",
    transform: (items: ExtendedAccount[]) => {
      return orderBy(items, [displayNameFromContact], ["asc"]);
    },
  },
];

interface TeamsListProps {
  accounts: ExtendedAccount[] | undefined;
  onClickMember: (member: ExtendedAccount) => void;
}

type MemberOperationsProps = {
  hasUnlimitedMembers?: boolean;
};

export const useMemberOperations = ({
  hasUnlimitedMembers,
}: MemberOperationsProps) => {
  const router = useRouter();
  const { oid, organization, isOwner } = useAuth();
  const { mutate: mutateOrgAccounts } = useOrganizationAccounts();

  const { hasFullAccess } = useAccessType();

  const activityApi = useSDKApi(ActivityApi);
  const generateRequestId = useRequestIdGenerator("Pages/teams");

  const snackbar = useSnackbar();
  const [removeMemberLoading, setRemoveMemberLoading] =
    useState<boolean>(false);
  const [removeMember, setRemoveMember] = useState<AccountType | null>(null);
  const [successfullyRemovedMember, setSuccessfullyRemovedMember] =
    useState<AccountType | null>(null);

  const removedMemberName =
    successfullyRemovedMember &&
    displayNameFromContact(successfullyRemovedMember, true);

  const { apiCall: removeTeamMember } = useApi(removeOrganizationAccount, {
    failMode: "throw",
  });

  const canChangePermissions = hasFullAccess && hasUnlimitedMembers;
  const canRemoveMember = isOwner;

  const handleRemoveMember = async (account: AccountType) => {
    if (!oid || !account) return;
    setRemoveMemberLoading(true);
    try {
      await removeTeamMember(
        {
          userId: oid,
          accountId: account.id,
        },
        undefined,
        undefined
      );
      await mutateOrgAccounts();
      setSuccessfullyRemovedMember(account);
      activityApi.publishActivityMessage({
        publishActivityMessageRequest: {
          activityMessage: {
            action: ActivityAction.Unrelate,
            actorType: ActorType.Account,
            actorId: account.id,
            resourceType: ResourceType.Account,
            resourceId: account.id,
            organizationId: oid,
          },
        },
        xRequestId: generateRequestId(),
      });
    } catch (e: unknown) {
      const errorMessage = catchErrorClient(e, "Error removing member");
      snackbar.showWarning(errorMessage);
    } finally {
      setRemoveMemberLoading(false);
    }
  };

  const getDropdownItems = useCallback(
    (account: ExtendedAccount): DropdownItem[] =>
      compact<DropdownItem>([
        canChangePermissions && {
          type: "button",
          title: "Edit details",
          Icon: PencilIcon,
          onClick: () => {
            router.push(`/teams/${account.id}`);
          },
        },
        {
          type: "button",
          title: "Member Profile",
          Icon: TabIcon,
          onClick: () =>
            window?.open(
              `${getCoachBaseUrl(organization!, true)}/${account?.id}`
            ),
        },
        canRemoveMember && {
          type: "button",
          title: "Remove",
          Icon: BlockIcon,
          onClick: () => setRemoveMember(account),
          variant: "delete",
        },
      ]),
    [canChangePermissions, canRemoveMember]
  );

  return {
    UIElements: (
      <>
        {!!removeMember && (
          <RemoveMemberModal
            isPaying={hasUnlimitedMembers}
            account={removeMember}
            toggleShow={() => setRemoveMember(null)}
            handleRemoveMember={handleRemoveMember}
            removeMemberLoading={removeMemberLoading}
          />
        )}
        {!!successfullyRemovedMember && (
          <SmallModal
            toggleShow={() => {
              setSuccessfullyRemovedMember(null);
            }}
            heapModalName="removed_member_success_modal"
            title={`${removedMemberName} removed`}
            description={`${removedMemberName}'s account and access to your Practice team has been immediately removed`}
            {...sharedSmallModalProps}
          >
            {hasUnlimitedMembers && (
              <div className="flex items-center gap-3 rounded-md bg-grey-950 text-grey-500 px-3 py-2 text-sm">
                <InfoIcon />
                <p>
                  We&apos;ve updated your account and upcoming billing cycle
                </p>
              </div>
            )}
          </SmallModal>
        )}
      </>
    ),
    shouldShowDropdown: (account: ExtendedAccount) => {
      if (isOwner) return true;
      return !account.isOwner && (canChangePermissions || canRemoveMember);
    },
    Trigger: (account: ExtendedAccount, showOnHover?: boolean) => {
      const defaultHover = !showOnHover
        ? "hover:bg-action-900 active:bg-action-700"
        : "";
      return (
        <IconAction
          className={`bottom-0 right-0 m-auto !block !bg-grey-950 hover:!bg-action-900 hover:!text-black-ink !w-10 !h-10 ${defaultHover}`}
          type="dropdown"
          showOnHover={showOnHover}
          dropdownItems={getDropdownItems(account)}
        />
      );
    },
  };
};

const TeamsList: FC<TeamsListProps> = ({ accounts, onClickMember }) => {
  const { aid } = useAuth();
  const hasFullAccess = useHasFullAccess();
  const { SortDropdown, data: filteredItems } =
    useListConfiguration<ExtendedAccount>(
      accounts,
      SORT_OPTIONS,
      [
        {
          text: "Show pending",
          transform: (items: ExtendedAccount[], value: boolean) =>
            items.filter((item) => value || !!item.lastRefreshTime),
          key: "showPending",
          defaultValue: true,
        },
      ],
      "teamsPageConfiguration"
    );

  if (!filteredItems) return <LoadingSpinner className="mx-auto" />;

  return (
    <div className="mt-12 mb-14">
      <div className="flex justify-end mb-4">{SortDropdown}</div>
      <SelectableList<ExtendedAccount>
        items={filteredItems}
        selectable={false}
        rowRenderer={(account) => {
          const canOpenMemberProfile = hasFullAccess || account.id === aid;
          const { second, third, fourth } = getColumns(account);

          return (
            <div
              className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-[1fr_1fr_150px_100px] gap-2 md:gap-4 relative px-4 py-4 items-center"
              onClick={
                canOpenMemberProfile ? () => onClickMember(account) : undefined
              }
            >
              <PersonInformation
                person={account}
                StatusIcon={!account.lastRefreshTime ? PendingIcon : undefined}
              />
              <div className="hidden sm:block">{second}</div>

              <div className="hidden md:block">{third}</div>
              <div className="hidden md:flex justify-end items-center">
                {fourth}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

interface InviteMembersModalProps {
  show: boolean;
  toggle: () => void;
  hasUnlimitedMembers?: boolean;
}

const InviteMembersModal: FC<InviteMembersModalProps> = ({
  show,
  toggle,
  hasUnlimitedMembers,
}) => {
  const { oid, aid } = useAuth();
  const { mutate: mutateOrgAccounts } = useOrganizationAccounts();
  const snackbar = useSnackbar();

  const [invitedMemberEmail, setInvitedMemberEmail] = useState<string>("");

  const { value: invitationSent, toggle: toggleInvitationSentModal } =
    useToggle(false);

  const { apiCall: add } = useApi(addOrganizationAccount, {
    failMode: "throw",
  });

  const activityApi = useSDKApi(ActivityApi);
  const generateRequestId = useRequestIdGenerator("pages/teams");

  const onSubmit = async (data: FormData) => {
    if (!data.email || !oid) return;

    const email = data.email.toLowerCase();
    try {
      const response = await add(
        { userId: oid },
        { email, accessType: data.accessPermission },
        null
      );
      setInvitedMemberEmail(email);
      mutateOrgAccounts();
      toggleInvitationSentModal();
      activityApi.publishActivityMessage({
        publishActivityMessageRequest: {
          activityMessage: {
            action: ActivityAction.Relate,
            actorType: ActorType.Account,
            actorId: aid || "",
            resourceType: ResourceType.Account,
            resourceId: response?.data?.account?.id || "",
            organizationId: oid || "",
            data: {
              accessPermission: data.accessPermission,
            },
          },
        },
        xRequestId: generateRequestId(),
      });
    } catch (e: unknown) {
      const errorMessage = catchErrorClient(e, "Error inviting member");
      snackbar.showWarning(errorMessage, "");
    }
    toggle();
  };

  return (
    <>
      {show && (
        <MemberPermissionModal
          show
          hasUnlimitedMembers={hasUnlimitedMembers}
          variant="invite"
          toggleShow={toggle}
          onSubmit={onSubmit}
        />
      )}
      {invitationSent && (
        <SmallModal
          toggleShow={toggleInvitationSentModal}
          heapModalName="invite_members_success_modal"
          title="Invitation Sent"
          description={`We've sent an invitation to ${invitedMemberEmail} to join your team on Practice. `}
          {...sharedSmallModalProps}
        />
      )}
    </>
  );
};

const MemberTile: React.FC<{
  account: ExtendedAccount;
  extraContent?: ReactNode;
}> = ({ account, extraContent }) => {
  return (
    <div className="flex group relative flex-col space-y-0.5 h-48 justify-center rounded-2xl items-center bg-grey-950">
      <div className="relative">
        <ClientAvatar client={account} />
        {!account.lastRefreshTime && (
          <PendingIcon className="absolute top-0 right-0" />
        )}
      </div>
      <div>{displayNameFromContact(account)}</div>
      <div className="text-sm text-grey-500">{account.email}</div>
      {account.isOwner ? (
        <div className="text-grey-500 text-sm">Owner</div>
      ) : (
        <ActivityMessage account={account} />
      )}
      {extraContent}
    </div>
  );
};

const MemberTiles: React.FC<{
  accounts: ExtendedAccount[];
  onMemberInvite: () => void;
}> = ({ accounts, onMemberInvite }) => {
  const { oid } = useAuth();
  const [hasMembers, loadingMembers] = useHasFeature(oid, FeatureNames.members);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);

  const { Trigger, UIElements, shouldShowDropdown } = useMemberOperations({});

  const owner = useMemo(
    () => accounts.find((account) => account.isOwner)!,
    [accounts]
  );
  const partner = useMemo(
    () => accounts.find((account) => !account.isOwner)!,
    [accounts]
  );

  const getSecondTile = useCallback(() => {
    if (!hasMembers) {
      return (
        <div className="h-48 flex flex-col  items-start border-2 border-grey-900 rounded-2xl p-4 border-dashed">
          <div className="font-medium">Invite a partner</div>
          <div className="text-grey-500 text-sm mt-2">
            Partners or admins can help you with (almost) everything on
            Practice.
          </div>

          <Button
            className="mt-auto"
            onClick={() => setShowChangePlanModal(true)}
            smaller
            dark
          >
            Upgrade to pro
          </Button>
          <SubscriptionPlanModal
            show={showChangePlanModal}
            toggleShow={setShowChangePlanModal}
            onClose={() => setShowChangePlanModal(false)}
          />
        </div>
      );
    }

    if (accounts.length === 1) {
      return (
        <div className="h-48 flex flex-col justify-between items-start border-2 border-grey-900 rounded-2xl p-4 border-dashed">
          <ProBadgeIcon />
          <div className="font-medium">Invite a partner for Free</div>
          <div className="text-grey-500 text-sm">
            Partners or admins can help you with (almost) everything on
            Practice.
          </div>
          <Button onClick={onMemberInvite} smaller primary icon={<PlusIcon />}>
            Invite member
          </Button>
        </div>
      );
    }

    const extraContent = shouldShowDropdown(partner) && (
      <div className="absolute top-8 -right-1 group-hover:visible">
        {Trigger(partner, false)}
      </div>
    );

    return <MemberTile account={partner} extraContent={extraContent} />;
  }, [
    Trigger,
    accounts.length,
    partner,
    hasMembers,
    onMemberInvite,
    shouldShowDropdown,
  ]);

  if (loadingMembers) return <LoadingSpinner />;

  return (
    <div>
      <div className="text-xl mb-4">Members</div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <MemberTile account={owner} />
        {getSecondTile()}
      </div>
      {UIElements}
    </div>
  );
};

const Teams: FC = () => {
  const { oid } = useAuth();
  const { accounts, mutate } = useOrganizationAccounts();
  const router = useRouter();
  const { accountId } = router.query;
  const [selectedMember, setSelectedMember] = useState<ExtendedAccount | null>(
    null
  );

  const { hasFullAccess, loading: loadingPermissions } = useAccessType();
  const { value: showInviteModal, toggle: toggleInviteModal } =
    useToggle(false);

  const [hasUnlimitedMembers, loadingUnlimitedMembers] = useHasFeature(
    oid,
    FeatureNames.unlimitedMembers
  );
  const { Trigger, UIElements, shouldShowDropdown } = useMemberOperations({
    hasUnlimitedMembers,
  });

  const handleCloseSidebar = () => {
    setSelectedMember(null);
    router.replace("/teams", undefined, { shallow: true });
  };

  useEffect(() => {
    mutate();
  }, []);

  useEffect(() => {
    if (accountId) {
      const member = accounts?.find((a) => a.id === accountId);
      if (member) setSelectedMember(member);
    }
  }, [accountId]);

  const customTitle = (
    <>
      <div className="flex gap-3 items-center">
        <h1 className="sm:text-3xl sm:font-light text-black-ink">Teams</h1>
      </div>
      <div className="gap-2 text-grey-500 items-center hidden sm:flex">
        <ClientCheckIcon className="w-5 h-5 flex-none" />
        <h5>Manage your organization and collaborate with your team</h5>
      </div>
    </>
  );

  if (!loadingPermissions && !hasFullAccess) {
    return <Redirect to="/home" />;
  }

  return (
    <AppFrame
      variant="form"
      title="Teams"
      customTitle={customTitle}
      isLoading={loadingUnlimitedMembers || !accounts || loadingPermissions}
      headerButtons={
        hasUnlimitedMembers
          ? [
              {
                text: "Invite Members",
                onClick: toggleInviteModal,
                primary: true,
              },
            ]
          : undefined
      }
    >
      <SelectableListContextProvider>
        {hasUnlimitedMembers ? (
          <TeamsList accounts={accounts!} onClickMember={setSelectedMember} />
        ) : (
          <>
            <MemberTiles
              accounts={accounts!}
              onMemberInvite={toggleInviteModal}
            />
            <TeamsMarketingSection className="mt-12 sm:mt-16" />
          </>
        )}
        <InviteMembersModal
          show={showInviteModal}
          toggle={toggleInviteModal}
          hasUnlimitedMembers={hasUnlimitedMembers}
        />
      </SelectableListContextProvider>
      {!!selectedMember && (
        <>
          <TeamsSidebar
            onChangeMember={setSelectedMember}
            show
            toggleShow={handleCloseSidebar}
            memberId={selectedMember.id}
            onClickClose={handleCloseSidebar}
            actions={
              shouldShowDropdown(selectedMember)
                ? Trigger(selectedMember, true)
                : undefined
            }
          />
          {UIElements}
        </>
      )}
    </AppFrame>
  );
};

export default Teams;
