import { ClientOrganizationApi } from "@practice/sdk";
import useSWR from "swr";

import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

export const useClientOrganizationMembers = ({
  clientId,
  oid,
}: {
  oid?: string;
  clientId?: string;
}) => {
  const clientOrgApi = useSDKApi(ClientOrganizationApi);
  const generateRequestId = useRequestIdGenerator(
    "useClientOrganizationMembers"
  );

  return useSWR(`clientOrganizations/${oid}/${clientId}`, async () => {
    if (!oid || !clientId) return;
    return clientOrgApi.getMembers({
      clientOrganizationId: clientId,
      organizationId: oid,
      xRequestId: generateRequestId(),
    });
  });
};
