import { FC, useMemo } from "react";
import classNames from "classnames";
import { round } from "lodash";
import { useRouter } from "next/router";
import { useMemberOperations } from "pages/teams";
import pluralize from "pluralize";

import { useAuth } from "@contexts/auth";
import { useBillingRatesContext } from "@contexts/billing-rates";
import useHasFeature from "@hooks/use-has-feature";
import { useOrganizationAccounts } from "@hooks/use-organization-accounts";
import useToggle from "@hooks/use-toggle";
import { FeatureNames } from "@lib/constants/featureNames";
import { ExtendedAccountType } from "@lib/data/schemas/account";

import { useSessionBillingOvertimeAPI } from "@components/AccountsPayable/use-session-billing-cycle-summary-api";
import { formatValueCurrency } from "@components/AccountsPayable/utils";
import ActiveDashboardCard from "@components/Home/ActiveDashboardCard";
import Flag3Icon from "@components/Icons/Flag3Icon";
import TeamsSidebar from "@components/Teams/TeamsSidebar";

interface SessionIconProps {
  className?: string;
}

const SessionsIcon: FC<SessionIconProps> = ({ className }) => (
  <div
    className={classNames(
      "rounded-full bg-green-600 text-green-200 w-6 h-6 !flex items-center justify-center",
      className
    )}
  >
    <Flag3Icon className="w-4 h-4" />
  </div>
);

interface SessionBillingDashboardCardProps {
  className?: string;
}

const SessionBillingDashboardCard: FC<SessionBillingDashboardCardProps> = ({
  className,
}) => {
  const router = useRouter();
  const { oid, aid, account } = useAuth();
  const { accounts, mutate } = useOrganizationAccounts();
  const { currency = "" } = useBillingRatesContext();
  const { value: showTeamSidebar, toggle: toggleTeamSidebar } = useToggle();
  const { data: overtimeCycles, isLoading: isCycleSummaryFetching } =
    useSessionBillingOvertimeAPI(aid);
  const [hasUnlimitedMembers] = useHasFeature(
    oid,
    FeatureNames.unlimitedMembers
  );
  const { Trigger, UIElements, shouldShowDropdown } = useMemberOperations({
    accounts,
    updateTeamsList: mutate,
    hasUnlimitedMembers,
  });

  const totalSessions = useMemo(() => {
    if (!overtimeCycles?.billingCycles) return 0;

    return overtimeCycles?.billingCycles.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
  }, [overtimeCycles?.billingCycles]);

  const handleOpenTeamSidebar = () => {
    router.replace(
      { query: { ...router?.query, cycle: "overtime" } },
      undefined,
      {
        shallow: true,
      }
    );
    toggleTeamSidebar();
  };

  return (
    <>
      <ActiveDashboardCard
        className={className}
        onClick={handleOpenTeamSidebar}
        title="Accrued hours"
        content={formatValueCurrency(overtimeCycles?.amount ?? 0, currency)}
        description={pluralize("session", round(totalSessions, 2), true)}
        DescriptionIcon={SessionsIcon}
        isLoading={isCycleSummaryFetching}
      />
      {showTeamSidebar && !!account && (
        <>
          <TeamsSidebar
            show
            toggleShow={toggleTeamSidebar}
            memberId={account.id}
            onClickClose={toggleTeamSidebar}
            actions={
              shouldShowDropdown(account as ExtendedAccountType)
                ? Trigger(account as ExtendedAccountType, true)
                : undefined
            }
          />
          {UIElements}
        </>
      )}
    </>
  );
};

export default SessionBillingDashboardCard;
