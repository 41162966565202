import { FC } from "react";
import { useFormContext } from "react-hook-form";

import { useCurrencies } from "@hooks/data/currencies";

import SelectForm from "@components/Form/SelectForm";
import TimeZonePicker from "@components/TimeZonePicker";

import { CustomPayPeriod } from "./CustomPayPeriod";
import {
  getFirstPayPeriodOptions,
  payPeriodOptions,
  payPeriodStartOptions,
} from "./utils";

const SetupStepFields: FC = () => {
  const { currencies } = useCurrencies();

  const { control, watch } = useFormContext();
  const payPeriod = watch("payPeriod");

  const currencyOptions = currencies.map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));

  const renderPayPeriodSettings = () => {
    if (payPeriod === "custom") {
      return <CustomPayPeriod />;
    }
    const firstPayPeriodOptions = getFirstPayPeriodOptions(payPeriod);
    return (
      <>
        <SelectForm
          name="payPeriodStart"
          label="Pay period start"
          options={payPeriodStartOptions}
          control={control}
        />
        <SelectForm
          name="firstPayPeriod"
          label="First pay period"
          options={firstPayPeriodOptions}
          control={control}
        />
      </>
    );
  };

  return (
    <div className="grid gap-4 grid-cols-2">
      <SelectForm
        name="currency"
        label="Currency"
        options={currencyOptions}
        control={control}
      />
      <SelectForm
        name="payPeriod"
        label="Pay period"
        options={payPeriodOptions}
        control={control}
      />
      {renderPayPeriodSettings()}
      <div className="col-span-2">
        <TimeZonePicker
          control={control}
          label="The accounts payable cutoff at midnight based of your preferred timezone"
          name="timeZone"
          wrapperClassName="!mt-0"
        />
      </div>
    </div>
  );
};

export default SetupStepFields;
