import { useState } from "react";
import { postLinkAppointmentToPackage } from "api-services/definitions/appointments";
import { getContactAppointments } from "api-services/definitions/contact-appointments";
import { useApi, useApiGetMutate } from "api-services/endpoints";

import { useAuth } from "@contexts/auth";
import { useUpdateAppointmentsCache } from "@hooks/useUpdateAppointmentsCache";

import { useMutatePackages } from "./use-client-organization-data";
import useLogger from "./use-logger";

type LinkApptType = (props: {
  appointmentId: string;
  packageInstanceId: string;
  action?: "add" | "remove";
  packageInstanceOwnerId?: string;
  availabilityId?: string;
}) => Promise<void>;
type UseLinkPackageType = (props: {
  coachId: string;
  clientId: string;
  clientParentId?: string;
}) => {
  link: LinkApptType;
  loading: boolean;
  error?: string | null;
};

const useLinkPackage: UseLinkPackageType = ({
  coachId,
  clientId,
  clientParentId,
}) => {
  const [error, setError] = useState<string | null>(null);
  const { oid } = useAuth();
  const { apiCall, loading } = useApi(postLinkAppointmentToPackage, {
    failMode: "throw",
  });
  const mutatePackages = useMutatePackages({
    clientId,
    parentId: clientParentId,
  });

  const mutateContactAppointments = useApiGetMutate(
    oid ? getContactAppointments : undefined,
    {
      orgId: oid ?? "",
      contactId: clientId,
    },
    undefined,
    {
      ignoreQuery: true,
    }
  );
  const { onUpdateAppointment } = useUpdateAppointmentsCache();

  const { logger } = useLogger();

  const link: LinkApptType = async ({
    appointmentId,
    packageInstanceId,
    action = "add",
    packageInstanceOwnerId,
    availabilityId,
  }) => {
    setError(null);
    try {
      await apiCall(
        { userId: coachId, appointmentId },
        {
          packageInstanceId,
          action,
          packageInstanceOwnerId,
          availabilityId,
        },
        {}
      );
      await mutateContactAppointments();
      await onUpdateAppointment(appointmentId);
      await mutatePackages();
    } catch (err) {
      logger.error(err, "useLinkPackage");
      setError("Something went wrong!");
      throw err;
    }
  };

  return { link, loading, error };
};

export default useLinkPackage;
