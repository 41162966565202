import { cloneElement, FC, ReactNode } from "react";
import { UseFormMethods } from "react-hook-form";
import classNames from "classnames";

import { IconType, LayoutVariantType } from "@lib/shared-types";

import AlarmOffIcon from "@components/Icons/AlarmOffIcon";
import InfoIcon from "@components/Icons/InfoIcon";

import ActionsMenu, { MenuActionType } from "./ActionsMenu";
import BackButton from "./BackButton";
import HeaderButtons, { HeaderButtonType } from "./HeaderButtons";

export interface AppHeaderProps {
  variant?: LayoutVariantType;
  title?: string;
  customTitle?: ReactNode;
  descriptionIcon?: IconType;
  isATrustDescription?: boolean;
  description?: string;
  extraCustomButtons?: ReactNode;
  headerButtons?: HeaderButtonType[];
  actions?: MenuActionType[];
  extraHeaderContent?: ReactNode;
  openHeaderDropdown?: boolean;
  setOpenHeaderDropdown?: (value: boolean) => void;
  formMethods?: UseFormMethods;
  showOnlyDropdownOnHeader?: boolean;
  showBackButton?: boolean;
  customBackButtonClick?: () => any;
  wrapperClassName?: string;
}

const AppHeader: FC<AppHeaderProps> = ({
  variant = "default",
  title,
  customTitle,
  descriptionIcon: DescriptionIcon,
  isATrustDescription = false,
  description,
  extraCustomButtons,
  headerButtons,
  actions,
  extraHeaderContent,
  openHeaderDropdown,
  setOpenHeaderDropdown,
  formMethods,
  showOnlyDropdownOnHeader = false,
  showBackButton = true,
  customBackButtonClick,
  wrapperClassName,
}) => {
  const descriptionIconClassName = "w-5 h-5";
  return (
    <header className="relative z-20">
      <div
        className={classNames(
          "mx-auto max-w-screen-lg px-4 md:px-8 mb-2 sm:mb-4",
          wrapperClassName
        )}
      >
        <div className="flex items-center justify-between h-20 sm:h-28">
          <div className="flex items-center flex-1">
            {variant !== "main" && showBackButton && (
              <BackButton
                className="mr-4 sm:mr-6"
                useCloseIcon={variant === "form"}
                formMethods={formMethods}
                onClick={customBackButtonClick}
              />
            )}
            {customTitle && (
              <div
                className={classNames(
                  "w-full",
                  variant === "record" && "hidden sm:block"
                )}
              >
                {customTitle}
              </div>
            )}
            {title && !customTitle && (
              <div>
                <h1
                  className={classNames(
                    "sm:text-3xl sm:font-light text-black-ink",
                    variant === "main" && "text-xl"
                  )}
                >
                  {title}
                </h1>
                {description && (
                  <div
                    className={classNames(
                      "hidden sm:flex items-center mt-1 gap-2",
                      isATrustDescription ? "text-action-500" : "text-grey-500"
                    )}
                  >
                    {DescriptionIcon ? (
                      cloneElement(<DescriptionIcon />, {
                        className: descriptionIconClassName,
                      })
                    ) : isATrustDescription ? (
                      <AlarmOffIcon className={descriptionIconClassName} />
                    ) : (
                      <InfoIcon className={descriptionIconClassName} />
                    )}
                    <p className="leading-5">{description}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="ml-4 flex gap-2.5 sm:gap-4">
            {extraCustomButtons}
            {!!headerButtons?.length && (
              <HeaderButtons
                variant={variant}
                headerButtons={headerButtons}
                showOnlyDropdownOnHeader={showOnlyDropdownOnHeader}
              />
            )}
            {!!actions?.length && (
              <ActionsMenu
                variant={variant}
                actions={actions}
                openHeaderDropdown={openHeaderDropdown}
                setOpenHeaderDropdown={setOpenHeaderDropdown}
              />
            )}
          </div>
        </div>
        {variant === "record" && (
          <div className="block sm:hidden">{customTitle}</div>
        )}
        {extraHeaderContent}
      </div>
    </header>
  );
};

export default AppHeader;
