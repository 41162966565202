import { FC, useMemo, useState } from "react";
import {
  BillableRequest,
  BillableRequestApi,
  BillableRequestStatus,
} from "@practice/sdk";
import classNames from "classnames";
import moment from "moment";
import Link from "next/link";

import { useAuth } from "@contexts/auth";
import { useContact } from "@hooks/data/clients";
import { useOrganizationAccounts } from "@hooks/use-organization-accounts";
import { useSDKApiExecute } from "@hooks/use-sdk-api";
import useSnackbar from "@hooks/use-snackbar";
import { useCheckScreenSize } from "@hooks/use-window-size";
import { displayNameFromContact } from "@lib/contacts";
import pluralHelper from "@lib/utils/pluralHelper";

import { useSessionBillingContext } from "@components/AccountsPayable/session-billing-context";
import { formatValueCurrency } from "@components/AccountsPayable/utils";
import { Button } from "@components/Button";
import ClientAvatar from "@components/Client/ClientAvatar";
import CheckIcon from "@components/Icons/CheckIcon";
import CloseIcon from "@components/Icons/CloseIcon";
import DisableIcon from "@components/Icons/DisableIcon";
import LoadingSpinner from "@components/LoadingSpinner";
import SmallModal from "@components/Modals/SmallModal";
import MultipleActions from "@components/MultipleActions";
import {
  SelectableList,
  useSelectableListContext,
} from "@components/SelectableList";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

import BillableRequestEmptyState from "./BillableRequestEmptyState";
import {
  useFetchBillableRequests,
  useGetBillableRequestsContext,
} from "./BillableRequestsContext";
import { ModeType } from "./BillableRequestsModal";

interface BillableRequestsTableListPros {
  trigger: {
    intersectionRootSelector?: string;
  };
}

const ClientField = ({
  clientId,
  mode,
}: {
  clientId?: string;
  mode?: ModeType;
}) => {
  const { contact } = useContact(clientId);

  if (!contact) return null;

  return (
    <Link
      href={`/contacts/${clientId}`}
      className="flex items-center space-x-2"
      target="_blank"
    >
      {mode === ModeType.COACH && (
        <ClientAvatar size="xxsmall" className="!mx-0" client={contact} />
      )}
      <div className="truncate">{displayNameFromContact(contact)}</div>
    </Link>
  );
};

const BillableRequestsTableList: FC<BillableRequestsTableListPros> = ({
  trigger,
}) => {
  const { oid, organization } = useAuth();
  const { accounts } = useOrganizationAccounts();
  const { isMD, isLG } = useCheckScreenSize();

  const currency = organization?.sessionBillingSettings?.currency ?? "USD";

  const accountsMap = useMemo(() => {
    if (!accounts) return new Map();
    return new Map(accounts.map((account) => [account.id, account]));
  }, [accounts]);

  const [itemToDelete, setItemToDelete] = useState<BillableRequest>();

  const { mode, filters, numbers, refresh } = useGetBillableRequestsContext();

  const { items, loadMore, loading } = useFetchBillableRequests();

  const { getCycleSummary } = useSessionBillingContext();
  const snackbar = useSnackbar();

  const { execute: cancelBillableRequest, loading: cancelling } =
    useSDKApiExecute(BillableRequestApi, {
      method: "cancelBillableRequest",
      keyOrigin: "BillableRequestsTableList",
      errorLogMessage: "Failed to cancel request",
      showErrorSnackbar: true,
      onSuccess: async () => {
        await refresh();
        snackbar.showMessage("Request cancelled");
      },
    });

  const { execute: postBatchApproveBillableRequests, loading: batchUpdating } =
    useSDKApiExecute(BillableRequestApi, {
      method: "postBatchApproveBillableRequests",
      keyOrigin: "BillableRequestsTableList",
      errorLogMessage: "Failed to approve requests",
      showErrorSnackbar: true,
      onSuccess: async (result) => {
        await refresh();
        snackbar.showMessage(result.message);
      },
    });

  const { execute: changeBillableRequestStatus, loading: singleUpdating } =
    useSDKApiExecute(BillableRequestApi, {
      method: "changeBillableRequestStatus",
      keyOrigin: "BillableRequestsTableList",
      errorLogMessage: "Failed to change request status",
      showErrorSnackbar: true,
      onSuccess: async (_, data) => {
        await refresh();
        if (data.accountId)
          getCycleSummary(
            data.accountId,
            new Date(filters.cycleStart!),
            new Date(filters.cycleEnd!)
          );
      },
    });

  const getAmount = (item: BillableRequest) => {
    const billingRate = item.billingRate;
    const value =
      billingRate?.overwriting?.[item.accountId]?.value ?? billingRate.value;

    const amount = value * (item.duration / 60);
    return formatValueCurrency(amount, currency, 2);
  };

  const renderEmpty = (
    <BillableRequestEmptyState mode={mode} status={filters.status} />
  );

  const getActions = (item: BillableRequest) => {
    if (mode === ModeType.COACH) {
      return (
        <Button
          className="p-0.5"
          icon={
            <Tooltip
              trigger={<CloseIcon className="h-4 w-4 text-grey-500" />}
              contentClassName="px-3 py-1 !text-xs"
              size={TooltipSize.small}
            >
              Cancel request
            </Tooltip>
          }
          onClick={() => setItemToDelete(item)}
        />
      );
    }
    const updatingId = singleUpdating?.data?.id;
    const disabled = updatingId === item.id;

    if (
      filters.status === BillableRequestStatus.Approved ||
      filters.status === BillableRequestStatus.Rejected
    ) {
      return (
        <Button
          className="p-0.5"
          icon={
            <Tooltip
              trigger={<CloseIcon className="h-4 w-4 text-grey-500" />}
              contentClassName="px-3 py-1 !text-xs"
              size={TooltipSize.small}
            >
              Unapprove request
            </Tooltip>
          }
          isLoading={
            updatingId === item.id && singleUpdating?.loadingId === `submitted`
          }
          disabled={disabled || batchUpdating}
          onClick={() => {
            changeBillableRequestStatus(
              {
                billableRequestId: item.id,
                changeBillableRequestStatusRequest: {
                  status: BillableRequestStatus.Submitted,
                },
                organizationId: oid!,
              },
              { data: item, loadingId: `submitted` }
            );
          }}
        />
      );
    }

    return (
      <>
        <Button
          className="p-0.5"
          icon={<CloseIcon className="h-4 w-4 text-grey-500" />}
          isLoading={
            updatingId === item.id && singleUpdating?.loadingId === `reject`
          }
          disabled={disabled || batchUpdating}
          onClick={() => {
            changeBillableRequestStatus(
              {
                billableRequestId: item.id,
                changeBillableRequestStatusRequest: {
                  status: BillableRequestStatus.Rejected,
                },
                organizationId: oid!,
              },
              { data: item, loadingId: `reject` }
            );
          }}
        />
        <Button
          className="p-0.5"
          icon={<CheckIcon className="h-4 w-4" />}
          isLoading={
            updatingId === item.id && singleUpdating?.loadingId === `approve`
          }
          disabled={disabled || batchUpdating}
          primary
          onClick={() => {
            changeBillableRequestStatus(
              {
                billableRequestId: item.id,
                changeBillableRequestStatusRequest: {
                  status: BillableRequestStatus.Approved,
                },
                organizationId: oid!,
              },
              { data: item, loadingId: `approve` }
            );
          }}
        />
      </>
    );
  };

  const tableStyles = useMemo(() => {
    const isApprovedTab = filters?.status === BillableRequestStatus.Approved;
    const isReviewer = mode === ModeType.REVIEWER;
    const gridColsWidth = [
      // Member
      ...(isReviewer ? ["1.5fr"] : []),
      // Type
      "1.5fr",
      // Client
      "2fr",
      // Expensed
      "1fr",
      // Requested
      "1fr",
      // Approved
      ...(isApprovedTab ? ["1fr"] : []),
      // Duration
      "1fr",
      // Description
      ...(isLG ? ["2fr"] : []),
      // Amount
      "0.5fr",
      // Actions
      "80px",
    ];
    const gridCols = gridColsWidth.join(" ");
    return {
      wrapperStyles: {
        gridTemplateColumns: gridCols,
      },
      wrapper: classNames(`flex-1 grid gap-2 px-4`),
      headerText: "text-sm text-grey-500",
    };
  }, [mode, filters?.status]);

  const { totalSelected, selectedAll, selectedIds, setSelectedAll } =
    useSelectableListContext();

  const totalItems = numbers?.[filters.status!] || 0;

  const itemsToIgnore = useMemo(
    () => items?.filter((item) => !selectedIds.includes(item.id)) ?? [],
    [items, selectedIds]
  );

  const renderMultipleAction = () => {
    const isSelectedAll = items?.length === totalSelected;
    const totalToIgnore = itemsToIgnore.length;
    const total = selectedAll ? totalItems - totalToIgnore : totalSelected;

    const label =
      selectedAll && isSelectedAll
        ? "All requests"
        : pluralHelper(total, "request");
    return (
      <MultipleActions
        label={`${label} selected`}
        actions={[
          {
            icon: <CheckIcon className="text-white" />,
            onClick: () => {
              return postBatchApproveBillableRequests(
                {
                  postBatchApproveBillableRequestsRequest: selectedAll
                    ? {
                        all: {
                          query: {
                            start: filters.cycleStart
                              ? new Date(filters.cycleStart)
                              : undefined,
                            end: filters.cycleEnd
                              ? new Date(filters.cycleEnd)
                              : undefined,
                            accountId: filters.accountId,
                          },
                          idsToIgnore: itemsToIgnore.map((item) => item.id),
                        },
                      }
                    : { items: selectedIds.map((id) => ({ id })) },
                  organizationId: oid!,
                },
                { id: "batch-approve", accountId: filters.accountId }
              );
            },
            clearSelection: true,
            tooltip: "Approve",
          },
          {
            icon: <CloseIcon className="text-white" />,
            clearSelection: true,
            className: "bg-transparent",
            onClick: () => setSelectedAll(false),
            tooltip: "Clear selection",
          },
        ]}
      />
    );
  };

  const headers = useMemo(() => {
    const isApprovedTab = filters?.status === BillableRequestStatus.Approved;
    const isReviewer = mode === ModeType.REVIEWER;
    return [
      ...(isReviewer ? ["Member"] : []),
      "Type",
      "Client",
      ...(isMD ? ["Expensed"] : []),
      ...(isMD ? ["Requested"] : []),
      ...(isMD && isApprovedTab ? ["Approved"] : []),
      ...(isLG ? ["Duration", "Description"] : []),
      "Amount",
      " ",
    ];
  }, [mode, filters?.status]);

  const selectionEnabled =
    mode === ModeType.REVIEWER &&
    filters.status === BillableRequestStatus.Submitted;

  return (
    <div className="h-full">
      <SelectableList
        items={items}
        emptyList={renderEmpty}
        loadByScreenSize={false}
        selectable={selectionEnabled}
        loadingPlaceholder={<LoadingSpinner className="m-auto" />}
        alwaysShowCheckbox={selectionEnabled}
        styleOptions={{
          withBorder: false,
          withoutHover: true,
        }}
        isLoading={loading}
        header={
          <div
            className={classNames(tableStyles.wrapper)}
            style={tableStyles.wrapperStyles}
          >
            {headers.map((title) => (
              <div className={classNames(tableStyles.headerText)} key={title}>
                {title}
              </div>
            ))}
          </div>
        }
        showMultiSelect={selectionEnabled}
        rowRenderer={(item) => {
          const account = accountsMap.get(item.accountId);
          const memberLink = `/teams/${item.accountId || ""}`;
          const isApprovedTab =
            isMD && filters?.status === BillableRequestStatus.Approved;

          return (
            <div
              style={tableStyles.wrapperStyles}
              className={classNames(
                tableStyles.wrapper,
                "flex items-center text-sm h-10",
                `billable-request-item-${item.id}`
              )}
            >
              {mode === ModeType.REVIEWER ? (
                <div className="flex items-center space-x-2 truncate">
                  <ClientAvatar
                    size="xsmall"
                    className="!mx-0"
                    client={account}
                  />
                  <div className="truncate">
                    <Link href={memberLink} target="_blank">
                      {displayNameFromContact(account)}
                    </Link>
                  </div>
                </div>
              ) : null}
              <div className="truncate">{item.billingRate.title}</div>
              <div>
                <ClientField clientId={item.clientId} mode={mode} />
              </div>
              {isMD && <div>{moment(item.date).format("MMM D")}</div>}
              {isMD && <div>{moment(item.dateCreated).format("MMM D")}</div>}
              {isApprovedTab && (
                <div>{moment(item.dateUpdated).format("MMM D")}</div>
              )}
              {isLG && <div>{item.duration}min</div>}
              {isLG &&
                (item.description ? (
                  <Tooltip
                    trigger={
                      <div className="line-clamp-1">
                        <div>{item.description}</div>
                      </div>
                    }
                    contentClassName="px-3 py-1 !text-xs !m-0"
                    size={TooltipSize.small}
                    placement="bottom"
                  >
                    {item.description}
                  </Tooltip>
                ) : (
                  <div></div>
                ))}
              <div>{getAmount(item)}</div>
              <div className="flex items-center justify-end space-x-2">
                {getActions(item)}
              </div>
            </div>
          );
        }}
        trigger={{
          onIntersection: loadMore,
          intersectionRootSelector: trigger.intersectionRootSelector,
          className: "!m-0",
        }}
      />
      {renderMultipleAction()}
      <SmallModal
        show={!!itemToDelete}
        toggleShow={() => setItemToDelete(undefined)}
        onAction={async () => {
          if (!itemToDelete) return;
          await cancelBillableRequest({
            billableRequestId: itemToDelete.id,
            organizationId: oid!,
          });
          setItemToDelete(undefined);
        }}
        icon={DisableIcon}
        iconColor="peach"
        title="Cancel request?"
        description="This action is irreversible but you can always create a new request."
        onActionText="Confirm cancellation"
        onCancelText="Nevermind"
        onActionLoading={cancelling}
      />
    </div>
  );
};

export default BillableRequestsTableList;
