import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const TemplateCreationIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("fill-current", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 12V6H5V12H11ZM19 5.99998L13 5.99998V12V14H5L5 20H13V22H5C3.89543 22 3 21.1046 3 20V14V12V6C3 4.89543 3.89543 4 5 4H11V3.99998H19C20.1046 3.99998 21 4.89542 21 5.99998V14H19V5.99998ZM15 12H17V16H21V18H17V22H15V18H11V16H15V12Z"
      />
    </svg>
  );
};

export default TemplateCreationIcon;
