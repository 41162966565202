import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.3169 14.3347C10.515 14.5327 10.8371 14.5295 11.0311 14.3275L15.6466 9.52178C15.8406 9.31977 16.1627 9.31652 16.3608 9.51457L17.0663 10.2201C17.2593 10.4131 17.2618 10.7254 17.0719 10.9215L11.0311 17.1589C10.8368 17.3595 10.5159 17.3621 10.3184 17.1646L7.35356 14.1997C7.1583 14.0045 7.1583 13.6879 7.35356 13.4926L8.06067 12.7855C8.25593 12.5903 8.57252 12.5903 8.76778 12.7855L10.3169 14.3347Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2H6V4H3V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V4H18V2H16V4H8V2ZM19 6H5V20H19V6Z"
      />
    </svg>
  );
};

export default Icon;
