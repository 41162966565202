import { useMemo } from "react";
import { sortBy } from "lodash";

import { useAuth } from "@contexts/auth";
import { FeatureNames } from "@lib/constants/featureNames";
import { displayNameFromContact } from "@lib/contacts";

import useHasFeature from "./use-has-feature";
import { useOrganizationAccounts } from "./use-organization-accounts";

type UseMemberFilterOptionsType = () => {
  options?: { text: string; value: string }[];
};

const useMemberFilterOptions: UseMemberFilterOptionsType = () => {
  const { oid, aid } = useAuth();
  const { accounts } = useOrganizationAccounts();
  const [hasTeams] = useHasFeature(oid, FeatureNames.members);

  const options = useMemo(() => {
    if (!hasTeams || !oid || !aid || !accounts) return;

    const options = accounts.map((acc) => ({
      text: displayNameFromContact(acc) || "",
      value: acc.id,
    }));

    const sortedOptions = sortBy(options, "text");

    return [{ text: "All members", value: "all" }, ...sortedOptions];
  }, [hasTeams, oid, aid, accounts]);

  return { options };
};

export default useMemberFilterOptions;
