import { PackageInstanceCard, PackageInstancePublic } from "@practice/sdk";
import { compact } from "lodash";

import { getNormalizedDate } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { getCycleFromDate } from "@lib/models/package-instances/utils";
import pluralHelper from "@lib/utils/pluralHelper";

import { countPackageAppointments } from "@components/Package/utils";
import {
  formatDuration,
  formatInMinutes,
  getPackageInstanceHelper,
  getTotalAppointmentsConsumed,
  getTotalSessions,
} from "@components/PackageInstance/utils";

export type PackageInstancesAppointmentsMapType = Record<
  string,
  {
    items: PackageInstanceCard["items"];
    quantity: number | string;
    appointments: AppointmentType[];
    packageInstance: PackageInstanceCard;

    // used to store the index of the appointment in the package instance
    [appointmentId: string]: any;
  }
>;

export const countPackageInstancesAppointmentsMap = (
  packageInstances: PackageInstanceCard[] | undefined,
  appointments: AppointmentType[] | undefined
) => {
  const packageInstancesAppointmentsMap: PackageInstancesAppointmentsMapType =
    {};
  if (packageInstances?.length && appointments?.length) {
    packageInstances?.forEach((packageInstance) => {
      const packageInstanceId = packageInstance?.id || "";
      const { contentType, distributeSessions } =
        getPackageInstanceHelper(packageInstance);

      packageInstancesAppointmentsMap[packageInstanceId] = {
        appointments: [],
        quantity: 0,
        items: packageInstance?.items,
        packageInstance: {
          ...packageInstance,
          contentType,
          distributeSessions,
        },
      };

      if (packageInstance?.items?.length) {
        packageInstancesAppointmentsMap[packageInstanceId].quantity =
          countPackageAppointments(packageInstance, null);
      }
    });
    appointments?.sort((a, b) => a?.start - b?.start);
    appointments?.forEach((appointment) => {
      const appointmentPackageInstanceId = appointment?.packageInstanceId || "";
      const appointmentId = appointment?.id;

      if (appointmentPackageInstanceId) {
        const currentPackageInstance =
          packageInstancesAppointmentsMap[appointmentPackageInstanceId];

        packageInstancesAppointmentsMap[
          appointmentPackageInstanceId
        ]?.appointments.push(appointment);

        // @TODO: Ask the team how can we improve this. We currently save the
        //        map expeting its value as an object, but this logic is also
        //        storing the current index of an appointment to display what
        //        order it should be show in the client record, and probably
        //        other part of the system as well.
        if (currentPackageInstance && currentPackageInstance.quantity) {
          const appointments = currentPackageInstance?.appointments;
          const mapIds = (appt: AppointmentType) => appt.id;
          const appointmentIds = appointments?.map(mapIds);
          const index = appointmentIds.indexOf(appointmentId) + 1;

          packageInstancesAppointmentsMap[appointmentPackageInstanceId][
            appointmentId
          ] = index;
        }
      }
    });
  }
  return packageInstancesAppointmentsMap;
};

/**
 * Returns the appointment duration in human readable format
 * */
const getPackageInstanceTagTimeBased = (
  appointment: AppointmentType,
  packageInstance: PackageInstanceCard
) => {
  const { timeType, isPackageTypeRecurring } =
    getPackageInstanceHelper(packageInstance);

  const isHourBased = timeType === "hours";
  const getIsoStringDate = (data: any) =>
    data?.toISOString ? data?.toISOString() : data;
  const normalizedAppointment = {
    ...appointment,
    start: getIsoStringDate(appointment?.start),
    end: getIsoStringDate(appointment?.end),
  } as unknown as AppointmentType;
  const appointmentDurationInMinutes = getTotalAppointmentsConsumed(
    [normalizedAppointment],
    "minutes"
  );
  const formattedDuration =
    isHourBased && appointmentDurationInMinutes > 60
      ? formatDuration(appointmentDurationInMinutes)
      : formatInMinutes(appointmentDurationInMinutes);

  const cycle = appointment?.start
    ? getCycleFromDate(packageInstance, getNormalizedDate(appointment.start))
    : null;

  const shouldRenderRecurringPrefix = isPackageTypeRecurring && cycle?.title;

  return compact([
    shouldRenderRecurringPrefix && `${cycle?.title}:`,
    formattedDuration,
  ]).join(" ");
};

const getPackageInstanceTagCycleBased = (
  packageInstance: PackageInstanceCard,
  appointment: AppointmentType
) => {
  const apptCycle = getCycleFromDate(
    packageInstance,
    getNormalizedDate(appointment?.start)
  );

  if (!apptCycle) {
    return "Future cycle";
  }
  return apptCycle.title;
};

export const getPackageInstanceTag = (
  packageInstanceId: string,
  appointmentId: string,
  packageInstancesAppointmentsMap?: PackageInstancesAppointmentsMapType
) => {
  if (!packageInstanceId || !appointmentId) return;
  let currentStep: any;
  let totalSteps: any;

  const currentItem = packageInstancesAppointmentsMap?.[packageInstanceId];

  if (!currentItem) return;

  const currentItemsExists =
    !!currentItem && !!Object.keys(currentItem)?.length;

  const packageInstance = currentItem.packageInstance;
  const {
    distributeSessions,
    isPackageTypeRecurring,
    isContentTypeTime,
    isUsagePackage,
  } = getPackageInstanceHelper(packageInstance);

  if (currentItemsExists) {
    currentStep = currentItem[appointmentId];
    totalSteps = getTotalSessions(packageInstance);
  }

  if (currentItemsExists && !distributeSessions) {
    currentStep = currentItem[appointmentId] ?? 0;
    totalSteps = packageInstance?.items?.reduce(
      (acc, item) => acc + item.quantity,
      0
    );
  }

  const appointment = currentItem.appointments.find(
    (appointment) => appointment.id === appointmentId
  );

  if (isContentTypeTime) {
    return getPackageInstanceTagTimeBased(
      appointment as AppointmentType,
      packageInstance
    );
  }

  if (isPackageTypeRecurring || isUsagePackage) {
    if (
      appointment?.start &&
      packageInstance?.startDate &&
      getNormalizedDate(appointment.start) < packageInstance.startDate
    ) {
      return "Past cycle";
    }

    return getPackageInstanceTagCycleBased(
      packageInstance,
      appointment as AppointmentType
    );
  }

  return currentStep && totalSteps && `${currentStep}/${totalSteps}`;
};

/**
 * Format the package content type/time with a value
 * Example: 1 hour, 2 hours, 1 appointment, 2 appointments, etc
 * */
export const formattedPackageContentTypeValue = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  value: number,
  type?: string
) => {
  const isContentTypeTime = packageInstance?.contentType === "time";
  const timeType = packageInstance?.timeType || "hours";
  const timeTypeSingular = timeType.substring(0, timeType.length - 1);
  const contentTypeSingular = isContentTypeTime
    ? timeTypeSingular
    : "appointment";
  const contentTypeToShow = pluralHelper(value, type ?? contentTypeSingular);
  return contentTypeToShow;
};
