import { useMemo } from "react";
import { ClientOrganizationApi } from "@practice/sdk";
import { Timestamp } from "firebase/firestore";
import useSWR, { useSWRConfig } from "swr";

import { useClientOrganizationMembers } from "./use-client-organization-members";
import { useRequestIdGenerator } from "./use-request-id-generator";
import { useSDKApi } from "./use-sdk-api";

export const useMutatePackages = ({
  clientId,
  parentId = "",
}: {
  clientId: string;
  parentId?: string;
}) => {
  const { mutate, cache } = useSWRConfig();

  return async () => {
    const promises: Promise<void>[] = [];
    for (const key of cache.keys()) {
      if (
        typeof key === "string" &&
        (key.includes(clientId) || key.includes(parentId)) &&
        (key.includes("client-organization") ||
          key.includes("package-instances-schedulers") ||
          key.includes("package-instances"))
      ) {
        promises.push(mutate(key));
      }
    }
    return Promise.all(promises);
  };
};

export const useGetClientOrganizationPackages = (query: {
  clientId?: string;
  memberId?: string;
  includeArtifacts?: boolean;
  oid?: string;
  includeInternalForms?: boolean;
}) => {
  const {
    clientId,
    memberId = "",
    includeArtifacts = false,
    oid,
    includeInternalForms = true,
  } = query;
  const generateRequestId = useRequestIdGenerator(
    "useGetClientOrganizationPackages"
  );
  const clientOrgApi = useSDKApi(ClientOrganizationApi);

  const { data: membersData, mutate } = useClientOrganizationMembers({
    clientId: clientId,
    oid: oid,
  });

  const { data: packageInstancesData } = useSWR(
    `organizations/${oid}/client-organization/${clientId}`,
    async () => {
      if (!oid || !clientId) return;
      return await clientOrgApi.getPackageInstances({
        clientOrganizationId: clientId,
        organizationId: oid!,
        xRequestId: generateRequestId(),
        includeArtifacts: String(includeArtifacts),
        memberId,
      });
    }
  );

  return useMemo(() => {
    if (!packageInstancesData || !membersData || !clientId)
      return {
        clientOrgAppointments: [],
        clientOrgNotes: [],
        clientOrgForms: [],
        clientOrgPackageInstancs: [],
        clientOrgInvoices: [],
        packageInstancesData: packageInstancesData,
        mutate,
      };

    const participatingPackageIds = packageInstancesData.packageInstances
      .filter((pi) => pi.participants?.includes(memberId))
      .map((pi) => pi.id);

    const supervisingPackageIds = packageInstancesData.packageInstances
      .filter((pi) => pi.supervisors?.includes(memberId))
      .map((pi) => pi.id);

    const clientOrgAppointments =
      packageInstancesData.appointments
        ?.filter((appt) => {
          return (
            supervisingPackageIds.includes(appt.packageInstanceId) ||
            (participatingPackageIds.includes(appt.packageInstanceId) &&
              appt.contactId === memberId) ||
            !memberId
          );
        })
        .map((appt) => ({
          ...appt,
          contacts: (appt.contactIds || [appt.contactId] || [])
            .map(
              (contactId) =>
                membersData?.members.find((m) => m.id === contactId)
            )
            .filter(Boolean),
        })) ?? [];

    const canViewNoteOrForm = (associatedAppointments: any[]) => {
      if (!memberId) return true;
      if (!associatedAppointments.length) return false;
      const associatedPackageIds = associatedAppointments
        .map((appt) => appt.packageInstanceId)
        .filter(Boolean);
      return (
        associatedPackageIds.some((id) =>
          participatingPackageIds.includes(id)
        ) ||
        associatedPackageIds.some((id) => {
          const packageInstance = packageInstancesData.packageInstances.find(
            (pi) => pi.id === id
          );
          return (
            supervisingPackageIds.includes(id) &&
            !packageInstance?.organizationSettings
              ?.hideNotesAndFormsFromManagers
          );
        })
      );
    };
    const clientOrgNotes = (packageInstancesData.notes ?? [])
      .map((note) => {
        const associatedAppointments = clientOrgAppointments.filter((appt) => {
          return note.appointmentIds.includes(appt.id);
        });

        if (!canViewNoteOrForm(associatedAppointments)) return;

        return {
          ...note,
          contacts: associatedAppointments.map((appt) => appt.contacts).flat(),
        };
      })
      .filter(Boolean);
    const allForms = [...(packageInstancesData?.forms ?? [])];

    if (includeInternalForms) {
      allForms.push(...(packageInstancesData?.internalForms ?? []));
    }
    const clientOrgForms = allForms
      .map((form) => {
        const associatedAppointments = clientOrgAppointments.filter(
          (appt) => form.appointmentId === appt.id
        );

        if (!canViewNoteOrForm(associatedAppointments)) return;

        return {
          ...form,
          createdAt: new Date(form.createdAt),
          contacts: associatedAppointments.map((appt) => appt.contacts).flat(),
        };
      })
      .filter(Boolean);

    const clientOrgPackageInstancs = (
      packageInstancesData.packageInstances ?? []
    ).map((pi) => ({
      ...pi,
      startDate: pi.startDate ? new Date(pi.startDate) : undefined,
      createdAt: new Date(pi.createdAt),
      payments: pi.payments?.map((payment) => ({
        ...payment,
        dueDate: payment.dueDate ? new Date(payment.dueDate) : null,
      })),
    }));

    const clientOrgInvoices = (packageInstancesData.invoices ?? []).map(
      (invoice) => ({
        ...invoice,
        createdAt: new Date(invoice.createdAt),
        dueDate: invoice.dueDate
          ? Timestamp.fromDate(new Date(invoice.dueDate))
          : null,
        contacts: [
          membersData?.members.find((m) => m.id === invoice.contactId),
        ]?.filter(Boolean),
      })
    );

    return {
      clientOrgAppointments,
      clientOrgNotes,
      clientOrgForms,
      clientOrgPackageInstancs,
      clientOrgInvoices,
      packageInstancesData,
      mutate,
    };
  }, [packageInstancesData, membersData, clientId, memberId]);
};
