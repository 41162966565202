import { FC, JSX, useMemo } from "react";
import { ExtendedAccount } from "@practice/sdk";
import { orderBy } from "lodash";
import { useRouter } from "next/router";
import { AccountIntegrations } from "pages/teams";

import { useAuth } from "@contexts/auth";
import {
  useHasFullAccess,
  useOrganizationAccounts,
} from "@hooks/use-organization-accounts";
import usePersistentState from "@hooks/use-persistent-state";
import { displayNameFromContact } from "@lib/contacts";

import BillingCardMember from "@components/AccountsPayable/BillingCardMember";
import { SessionBillingProvider } from "@components/AccountsPayable/session-billing-context";
import { BillableRequestsProvider } from "@components/BillableRequest/BillableRequestsContext";
import { ModeType } from "@components/BillableRequest/BillableRequestsModal";
import { Button } from "@components/Button";
import { ConditionalBusinessRenderer } from "@components/ConditionalTeamsRenderer";
import CloseIcon from "@components/Icons/CloseIcon";
import LeftArrowIcon from "@components/Icons/LeftArrowIcon";
import RightArrowIcon from "@components/Icons/RightArrowIcon";
import SideModal, { SideModalProps } from "@components/SideModal/SideModal";

import MemberAvatar from "./MemberAvatar";
import MemberInfo from "./MemberInfo";

interface TeamsSidebarProps extends SideModalProps {
  memberId: string;
  onClickClose: () => void;
  onChangeMember?: (member: ExtendedAccount) => void;
  actions?: JSX.Element;
}

const TeamsSidebar: FC<TeamsSidebarProps> = ({
  memberId,
  onClickClose,
  onChangeMember,
  actions,
  ...props
}) => {
  const router = useRouter();
  const hasFullAccess = useHasFullAccess();
  const { aid, oid, isOwner } = useAuth();
  const { accounts = [] } = useOrganizationAccounts();
  const { persistentValue } = usePersistentState("teamsPageConfiguration", {
    selected: "name",
  });

  // use the last sort configuration to render the member navigation
  // in the correct order
  const orderedAccounts = useMemo(() => {
    if (persistentValue?.selected === "activity") {
      const getDate = (account: ExtendedAccount): Date | string =>
        account.lastRefreshTime ?? account.createdAt;
      return orderBy<ExtendedAccount>(accounts, getDate, ["desc"]);
    }

    return orderBy<ExtendedAccount>(
      accounts,
      (account) => displayNameFromContact(account),
      ["asc"]
    );
  }, [JSON.stringify(persistentValue), accounts]);

  const account = useMemo(
    () => orderedAccounts.find((item) => item.id === memberId),
    [memberId, orderedAccounts]
  );

  const accountName = account && displayNameFromContact(account, true);

  const memberRole = useMemo(() => {
    if (account?.accessType === "full") {
      return "admin";
    } else if (account?.accessType === "elevated") {
      return "partner";
    } else {
      return "collaborator";
    }
  }, [account?.accessType]);

  const handleMemberChange = (index: number) => {
    const targetMember = orderedAccounts[index];
    onChangeMember?.(targetMember);
    router.replace(
      { query: { ...router?.query, accountId: targetMember.id } },
      undefined,
      {
        shallow: true,
      }
    );
  };

  const renderHeader = () => {
    const currentIndex = orderedAccounts.findIndex(
      (item) => item.id === memberId
    );
    const isFirst = currentIndex === 0;
    const isLast = currentIndex === orderedAccounts.length - 1;
    const buttonProps = {
      square: true,
      circle: true,
      smaller: true,
    };
    return (
      <div className="flex items-center justify-between mx-6">
        <Button icon={<CloseIcon />} onClick={onClickClose} {...buttonProps} />
        {hasFullAccess && onChangeMember && (
          <div className="flex items-center space-x-2">
            <Button
              icon={<LeftArrowIcon />}
              disabled={isFirst}
              onClick={() => handleMemberChange(currentIndex - 1)}
              {...buttonProps}
            />
            <Button
              icon={<RightArrowIcon />}
              disabled={isLast}
              onClick={() => handleMemberChange(currentIndex + 1)}
              {...buttonProps}
            />
          </div>
        )}
      </div>
    );
  };

  const renderMemberInfo = () => {
    if (!oid || !aid || !account) return null;
    const role = account.id === aid && isOwner ? "owner" : memberRole;

    return (
      <div className="p-6 border-b border-grey-900">
        <MemberAvatar className="mb-8" account={account} role={role} />
        <div className="flex relative">
          <MemberInfo className="flex-1" account={account} />
          {actions}
        </div>
        <AccountIntegrations account={account} />
      </div>
    );
  };

  return (
    <SideModal {...props}>
      {renderHeader()}
      {renderMemberInfo()}
      <ConditionalBusinessRenderer>
        <div className="grid gap-6 mx-6">
          <SessionBillingProvider>
            <BillableRequestsProvider
              mode={ModeType.REVIEWER}
              defaultFilters={{
                accountId: memberId,
              }}
            >
              <BillingCardMember
                className="mt-6"
                memberId={memberId}
                accountName={accountName ?? "this member"}
              />
            </BillableRequestsProvider>
          </SessionBillingProvider>
        </div>
      </ConditionalBusinessRenderer>
    </SideModal>
  );
};

export default TeamsSidebar;
